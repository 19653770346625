import * as React from 'react';
import classNames from 'classnames';
import styles from './pricing.module.scss';
import Navbar from '../components/Navbar';
import Head from '../components/Head';
import Footer from '../components/Footer';
import LearnMore from '../components/LearnMore';

const PricingPage: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.PricingPage}>
      <Head title='Pricing for Independent Review Management Platform | Opinous' />
      <Navbar />
      <div className={styles.Header}>
        <div className={styles.Content}>
          <h1>Pricing built for businesses of all sizes.</h1>
          <h2>Opinous is designed to be cost effective for every business</h2>
        </div>
        <svg className={styles.Crop} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 48'>
          <path
            fill='#FFF'
            fillRule='evenodd'
            d='M0 0c194.22 23.31 345.236 37.383 453.045 42.218C539.007 46.073 627.992 48 720 48c265.097 0 505.097-16 720-48v48H0V0z'
          />
        </svg>
      </div>
      <div className={styles.PlansContainer}>
        <div className={styles.Plans}>
          <div className={styles.Main}>
            <div className={classNames(styles.Plan, styles.Standard)}>
              <h3>Professional</h3>
              <p>Access a complete review managment platform with simple, pay-as-you-go pricing</p>
              <div className={styles.Price}>
                £59
                <div className={styles.PriceFootnote}>(excl. VAT)</div>
                <div className={styles.Per}>
                  per location per month
                </div>
              </div>
              <ul className={styles.Features}>
                <li>
                  <span>
                    <i className='far fa-check' />
                  </span>{' '}
                  Everything you need to manage your reputation online
                </li>
                <li>
                  <span>
                    <i className='far fa-check' />
                  </span>{' '}
                  Fully managed platform with Drop-In integration
                </li>
                <li>
                  <span>
                    <i className='far fa-check' />
                  </span>{' '}
                  No fixed commitment - cancel at any time
                </li>
              </ul>
              <a href='https://manage.opinous.com/join'>
                Get Started in Minutes <i className='fas fa-arrow-right' />
              </a>
            </div>
          </div>
          <div>
            <div className={classNames(styles.Plan, styles.Enterprise)}>
              <h3>Enterprise</h3>
              <p>Design a custom package for your business</p>
              <p className={styles.Sub}>
                Available for businesses with many locations or specialised business requirements
              </p>
              <table className={styles.Features}>
                <tbody>
                  <tr>
                    <td>Volume discounts</td>
                    <td>Dedicated Account Manager</td>
                  </tr>
                  <tr>
                    <td>Whitelabel Platform</td>
                    <td>Centralised Reporting</td>
                  </tr>
                </tbody>
              </table>
              <a onClick={() => (window as any).Intercom('show')}>
                Contact Sales <i className='fas fa-arrow-right' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Faq}>
        <ul>
          <li>
            <h4>What forms of payment do you accept?</h4>
            <p>
              For our Professional plan we accept card payments from Visa, Mastercard and American
              Express. We work with <a href='https://stripe.com'>Stripe</a>, a leading payments
              provider, to ensure that all payment information is handled securely.
            </p>
          </li>
          <li>
            <h4>Do I have to pay to add extra users?</h4>
            <p>
              All Opinous plans are priced on the number of physical locations monitored on the
              platform. You can add as many users and customers as you want for any location with no
              limits.
            </p>
          </li>
          <li>
            <h4>What features does the free trial include?</h4>
            <p>
              Our 14 day, commitment free trial allows you to try our entire platform for a full 14
              days with no restrictions. As a trial user you will have access to all of the same
              tools as our paying customers.
            </p>
          </li>
          <li>
            <h4>Can I export my reviews if I chose to leave?</h4>
            <p>
              If you are not entirely satisfied with Opinous and decide to leave we are happy to
              provide all of your reviews in a standard interchange format so you can leave at any
              time.
            </p>
          </li>
        </ul>
      </div>
      <LearnMore />
      <Footer />
    </div>
  );
};

export default PricingPage;
